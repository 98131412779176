import React from 'react';
import GlobalHead from '../config/GlobalHead';
import GlobalStyle from '../config/GlobalStyle';
import { Provider } from '../contexts/context';
import Footer from '../modules/footer/Footer';
import Header from '../modules/navigation/Header';
import TermsWrapper from '../modules/terms/Terms';

const AlgemeneVoorwaarden = () => (
  <Provider>
    <GlobalStyle />
    <GlobalHead />
    <Header />
    <TermsWrapper />
    <Footer />
  </Provider>
);

export default AlgemeneVoorwaarden;
