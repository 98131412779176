import React from 'react';
import styled from 'styled-components';

const SpecialCosts = () => (
  <React.Fragment>
    <Styledh2>5. Bijzondere ereloonvoorwaarden </Styledh2>
    <h3>Artikel 1</h3>
    <p>
      Behoudens andersluidende overeenkomst gelden onderhavige Bijzondere Voorwaarden in aanvulling van de Algemene Voorwaarden.
      Alle erelonen vermeld in de hierna volgende artikelen zijn exclusief BTW en exclusief kosten.
    </p>
    <h3>Artikel 2</h3>
    <h4>Algemeen</h4>
    <p>
      De erelonen uitgedrukt in uurtarieven worden in functie van de waarde van het geschil vastgesteld. De waarde van het geschil
      wordt bepaald aan de hand van het bedrag van de gestelde vordering(en). Indien bedragen door een overheidsdienst of door een
      derde partij van de cliënt worden gevorderd omvat het bedrag van de vordering zowel de hoofdsom, als boeten, verhogingen,
      interesten en kosten. Indien meerdere vorderingen worden gesteld, worden de bedragen van de afzonderlijke vorderingen
      gecumuleerd om de waarde van het geschil te bepalen. Indien de cliënt als eisende partij optreedt wordt de waarde van het
      geschil bepaald aan de hand van het totaal bedrag van de door hem gestelde vordering(en). Indien in strafzaken de
      verbeurdverklaring wordt gevorderd, wordt het bedrag van de vermogensvoordelen als maatstaf genomen om de waarde van het
      geschil te bepalen. Indien de waarde van het geschil niet kan worden vastgesteld, wordt het ereloon volgens een basis
      uurtarief berekend.
    </p>
    <h4>Uurtarief</h4>
    <p>De uurtarieven worden als volgt vastgesteld, rekening houdend met de waarde van het geschil :</p>
    <p>Barema</p>
    <StyledTable>
      <tbody>
        <tr>
          <td>Waarde van het geschil</td>
          <td>Richtlijn uurtarief/euro</td>
        </tr>
        <tr>
          <td>1-10.000</td>
          <td>125</td>
        </tr>
        <tr>
          <td>10.000-50.000</td>
          <td>150</td>
        </tr>
        <tr>
          <td>50.0-100.000</td>
          <td>175</td>
        </tr>
        <tr>
          <td>Meer dan 100.000</td>
          <td>200</td>
        </tr>
      </tbody>
    </StyledTable>
    <p>
      Indien de waarde van het geschil niet kan worden bepaald wordt een forfaitair uurtarief gehanteerd als volgt : € 150,00 /
      uur
    </p>
    <h4>Evenredig tarief</h4>
    <p>
      In geval van een positief resultaat bedraagt het ereloon echter minimaal een percentage van de waarde van het geschil
      berekend op basis van volgende schijven:
    </p>
    <StyledTable>
      <tbody>
        <tr>
          <td>Waarde van het geschil / euro</td>
          <td>Percentage</td>
          <td>Gecumuleerd bedrag / euro</td>
        </tr>

        <tr>
          <td>1-50.000</td>
          <td>10%</td>
          <td>5000</td>
        </tr>
        <tr>
          <td>50.000-100.000</td>
          <td>7%</td>
          <td>8.500</td>
        </tr>
        <tr>
          <td>Meer dan 100.000</td>
          <td>4%</td>
          <td />
        </tr>
      </tbody>
    </StyledTable>
    <p>
      Er is sprake van een positief resultaat, zoals bedoeld in het vorige lid, wanneer hetzij de vordering van de cliënt geheel
      of gedeeltelijk wordt toegekend, hetzij de vordering van de tegenpartij, de administratie of het parket geheel of
      gedeeltelijk wordt verworpen. De erelonen berekend volgens het uurtarief worden verrekend met de evenredig vastgestelde
      erelonen.
    </p>
  </React.Fragment>
);

export default SpecialCosts;

const Styledh2 = styled.h2`
  border-left: 3px solid #d26426;
  padding-left: 5px;
  margin-bottom: 20px;
  margin-top: 20px;
`;

const StyledTable = styled.table`
  td {
    border-top: 1px solid black;
    padding: 5px;
  }
`;
