import React from 'react';
import { Flex, Box } from '@rebass/grid';
import styled from 'styled-components';
import CallToAction from '../../components/CallToAction';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AlgemeneVoorwaarden from '../../assets/files/algemene-voorwaarden.pdf';
import AboutTheFirm from './AboutTheFirm';
import YourPrivacy from './YourPrivacy';
import SpecialCosts from './SpecialCosts';
import GeneralTerms from './GeneralTerms';

const Terms = () => (
  <Flex justifyContent="center" p={3}>
    <TermsWrapper>
      <Flex justifyContent="center">
        <Styledh1>Algemene voorwaarden</Styledh1>
      </Flex>
      <Flex justifyContent="center">
        <p>
          De algemene voorwaarden zijn hieronder weergegeven. Klik op de download knop om het document in PDF formaat te
          downloaden.
        </p>
      </Flex>
      <Flex justifyContent="center">
        <CallToAction>
          <Styleda href={AlgemeneVoorwaarden} download>
            <Styledi icon={faFileDownload} />
            Algemene voorwaarden [PDF]
          </Styleda>
        </CallToAction>
      </Flex>
      <Flex justifyContent="center">
        <TopNote>
          Na dag- en ondertekening in te scannen en te mailen naar frank@reynaertadvocaten.be of per gewone post te versturen naar
          het kantoor.
        </TopNote>
      </Flex>

      <p>
        Dit document heeft tot doel om de cliënt te informeren over de modaliteiten van de dienstenlevering van REYNAERT ADVOCATEN
        conform de Wet van 26 maart 2010 (Dienstenwet) en de Anti-witwaswet van 18 september 2017 (tot voorkoming van het
        witwassen van geld en de financiering van terrorisme en tot beperking van het gebruik van contanten) ; zij bevat tevens de
        algemene voorwaarden die van toepassing zijn op contractuele en extra-contractuele relatie tussen het kantoor en de
        cliënt. Bij de aanvang van een relatie kantoor – cliënt wordt deze overeenkomst voor akkoord ondertekend en wordt deze van
        toepassing voor alle dossiers die in het verleden en in de toekomst voor die cliënt worden behandeld.
      </p>

      <AboutTheFirm />
      <YourPrivacy />
      <GeneralTerms />

      <h3>7. Toepasselijk recht en bevoegde rechtbank</h3>
      <p>
        De partijen komen overeen dat Belgisch recht exclusief van toepassing is op deze Algemene Voorwaarden, op elke
        overeenkomst waarop deze laatste betrekking hebben en op de opdracht in het algemeen. De rechtbanken van het rechtsgebied
        waar REYNAERT ADVOCATEN haar kantoor houdt, zullen exclusief bevoegd zijn voor elk geschil betreffende de interpretatie of
        de uitvoering van deze Algemene Voorwaarden of de overeenkomst waarop deze betrekking hebben. Bovendien komen de partijen
        uitdrukkelijk en conventioneel overeen dat elke andere rechtbank dan deze aangeduid in de vorige paragraaf, onbevoegd is
        met betrekking tot de in de vorige paragraaf vermelde materie.
      </p>
      <SpecialCosts />
      <p>
        <i>Na het uitprinten van bijgevoegde PDF-file :</i>
      </p>
      <p>
        Handtekening, naam, en in voorkomend geval hoedanigheid van vertegenwoordiging, van de cliënt, voorafgegaan door de
        handgeschreven tekst “gelezen en goedgekeurd”
      </p>
      <p>Hierbij verklaar ik eveneens kennis te hebben genomen van de ereloontarieven en facturatievoorwaarden.</p>
      <Flex flexGrow="1">
        <SignatureHeader with={[1, 1 / 2]}>
          <p>Datum</p>
        </SignatureHeader>
        <SignatureHeader with={[1, 1 / 2]}>
          <p>Handtekening</p>
        </SignatureHeader>
      </Flex>

      <Flex justifyContent="center">
        <Note>
          Na dag- en ondertekening in te scannen en te mailen naar frank@reynaertadvocaten.be of per gewone post te versturen naar
          het kantoor
        </Note>
      </Flex>
      <Flex justifyContent="center">
        <CallToAction>
          <Styleda href={AlgemeneVoorwaarden} download>
            <Styledi icon={faFileDownload} />
            Algemene voorwaarden [PDF]
          </Styleda>
        </CallToAction>
      </Flex>
    </TermsWrapper>
  </Flex>
);

export default Terms;

const Note = styled.p`
  margin-top: 100px;
`;

const TopNote = styled.p`
  margin-bottom: 50px;
  padding-bottom: 50px;
  border-bottom: 1px solid rgb(33, 37, 40);
`;
const TermsWrapper = styled(Box)`
  max-width: 1128px;
`;

const Styledh1 = styled.h1`
  color: #d26426;
  margin-bottom: 20px;
`;

const Styleda = styled.a`
  color: white;
  align-self: center;
  &:hover {
    cursor: pointer;
  }
  &.external {
    color: #d26426;
  }
`;

const Styledi = styled(FontAwesomeIcon)`
  margin-right: 5px;
  height: 1rem;
  color: white;
`;
const SignatureHeader = styled(Box)`
  flex-grow: 1;
`;
