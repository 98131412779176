import React from 'react';
import styled from 'styled-components';

const YourPrivacy = () => (
  <React.Fragment>
    <Styledh2>Uw privacy</Styledh2>
    <p>
      REYNAERT ADVOCATEN verbindt zich ertoe om te allen tijde de naleving te verzekeren van alle toepasselijke wetten en
      reglementen inzake gegevensbescherming. Wanneer uw persoonsgegevens verzameld en gebruikt worden, handelt REYNAERT ADVOCATEN
      als verantwoordelijke voor de bescherming van uw gegevens enkel met als doel deze voor de noodwendigheden van uw dossier
      (weze het als contentieux, dan wel louter in het kader van een advies) aan te wenden.
    </p>
    <p>REYNAERT ADVOCATEN verzamelt o.m. de volgende persoonsgegevens:</p>
    <ul>
      <li>Naam, voornaam en adres (zowel in het toepasselijke geval als privaat persoon, dan wel als onderneming)</li>
      <li>Contactgegevens</li>
      <li>Telefoonnummer</li>
      <li>E-mailadres</li>
      <li>Btw en Kbo nummer</li>
      <li>Documenten vereist in het kader van de anti-witwaswetgeving. (bv. kopie van uw identiteitskaart)</li>
    </ul>
    <p>
      REYNAERT ADVOCATEN zal in ieder geval de vraag naar persoonsgegevens beperken tot wat strikt nodig is om u de producten of
      diensten te bezorgen waarvoor u belangstelling getoond heeft. Indien u ons echter niet de gevraagde gegevens bezorgt, is het
      mogelijk dat wij u geen diensten kunnen leveren.
    </p>
    <p>Wij kunnen uw persoonsgegevens gebruiken voor volgende doeleinden:</p>
    <h3>a/ algemene gegevens</h3>
    <p>
      In het kader van onze dienstverlening en onze activiteiten verzamelen en verwerken wij de identiteits- en contactgegevens
      van onze cliënten, hun personeel, medewerkers, aangestelden en andere nuttige contactpersonen. De doeleinden voor deze
      verwerkingen zijn de uitvoering van de overeenkomsten met onze cliënten, cliëntenbeheer, de boekhouding en direct
      marketingactiviteiten zoals het toesturen van promotionele of commerciële informatie en onze nieuwsbrieven. De rechtsgronden
      zijn de uitvoering van de overeenkomst, het vervullen van wettelijke, reglementaire en/of deontologische verplichtingen
      en/of ons gerechtvaardigd belang.
    </p>
    <h3>b/ bijzondere en/of gevoelige persoonsgegevens</h3>
    <p>
      Bijzondere en/of gevoelige persoonsgegevens worden door ons uitsluitend verwerkt voor het uitvoeren van een overeenkomst of
      het vervullen van een wettelijk, reglementair en/of deontologische verplichting.
    </p>
    <p>Wij verwerken uw persoonsgegevens enkel voor:</p>
    <ul>
      <li>de uitvoering van de dienstenovereenkomst die afgesloten werd tussen u en REYNAERT ADVOCATEN</li>
      <li>voor de nakoming van onze wettelijke verplichtingen of</li>
      <li>
        wanneer wij een rechtmatig belang hebben dit te doen en uw recht op privacybescherming daarbij niet geschonden wordt (bv.
        versturen van nieuwsbrieven).
      </li>
    </ul>
    <p>Onder bepaalde voorwaarden hebt u het recht om:</p>
    <ul>
      <li>Een kopie van uw persoonsgegevens te vragen</li>
      <li>Uw persoonsgegevens te verbeteren</li>
      <li>Het wissen van persoonsgegevens te vragen</li>
      <li>Bezwaar te maken tegen de verwerking van uw persoonsgegevens (bv. voor marketing doeleinden)</li>
      <li>Uw toestemming te allen tijde in te trekken </li>
    </ul>
    <p>
      Wij stellen alles in het werk om op een zorgvuldige en legitieme manier om te gaan met uw persoonsgegevens in
      overeenstemming met de toepasselijke regelgeving. Indien u desalniettemin van oordeel bent dat uw rechten geschonden zouden
      zijn en u binnen onze onderneming voor uw bezorgdheden geen gehoor zou vinden, staat het u vrij een klacht in te dienen bij
      de Gegevensbeschermingsautoriteit. Alle info daaromtrent kunt u terugvinden op de website:{' '}
    </p>
    <a target="_blank" rel="noreferrer noopener" href="https://www.gegevensbeschermingsautoriteit.be/verzoek-klacht-indienen">
      https://www.gegevensbeschermingsautoriteit.be/verzoek-klacht-indienen
    </a>
    <p>
      REYNAERT ADVOCATEN zet zich volledig in voor de bescherming van uw persoonsgegevens door gepaste organisatorische en
      technische maatregelen te nemen tegen niet-toegestane toegang tot de gegevens, onrechtmatige vernietiging of onvoorzien
      verlies ervan.
    </p>
    <p>
      Uw gegevens worden niet langer bewaard dan noodzakelijk volgens de bovenvermelde doeleinden. In normale gevallen bedraagt
      deze termijn vijf jaar na het afsluiten van uw dossier.
    </p>
    <p>
      Het is mogelijk dat wij uw persoonsgegevens aan derden meedelen, inclusief wetshandhavingsinstanties, wanneer wij daartoe
      wettelijk verplicht zijn, bv. in de strijd tegen het witwassen van geld.
    </p>
    <p>
      Het is mogelijk dat onze website links bevat naar andere websites die niet door REYNAERT ADVOCATEN beheerd worden. REYNAERT
      ADVOCATEN is niet aansprakelijk voor privacymaatregelen of de inhoud van deze websites.
    </p>
  </React.Fragment>
);

export default YourPrivacy;

const Styledh2 = styled.h2`
  border-left: 3px solid #d26426;
  margin-bottom: 20px;
  margin-top: 20px;
  padding-left: 5px;
`;
