import React from 'react';
import styled from 'styled-components';

const GeneralTerms = () => (
  <React.Fragment>
    <Styledh2>4. Algemene Voorwaarden</Styledh2>
    <Styledh3>1. Algemeen</Styledh3>
    <p>
      Deze Algemene Voorwaarden zijn van toepassing op de diensten geleverd door en/of voor advocaten van REYNAERT ADVOCATEN met
      als KBO nr. 0679.457.482 en met BTW nr. BE 0679.457.482 en houdt kantoor te 8670 Koksijde, Zonstraat 21.
    </p>
    <Styledh3>2. Erelonen en uitgaven</Styledh3>
    <Styledh4>Erelonen:</Styledh4>
    <p>
      Behoudens andersluidende overeenkomst en met voorbehoud van het recht om deze lopende de behandeling van het dossier aan te
      passen, worden de diensten in normale omstandigheden meestal maar niet exclusief op basis van standaarduurtarieven
      aangerekend. Een definitieve afrekening is evenwel mogelijk volgens het behaalde resultaat.
    </p>
    <Styledh4>Voorschotten:</Styledh4>
    <p>
      REYNAERT ADVOCATEN kan voor de aanvang van de opdracht en lopende de behandeling van de zaak één of meerdere voorschotten
      vragen aan de cliënt. Een voorschot is het forfaitair bedrag dat de cliënt betaalt aan de advocaat, voorafgaand aan een
      gedetailleerde staat van kosten en erelonen. In de tussentijdse staten en/of in de eindstaat van kosten en erelonen worden
      de voorschotten op het totale bedrag in mindering gebracht.
    </p>
    <Styledh4>Niet-betaling door cliënt:</Styledh4>
    <p>
      Al onze facturen zijn contant betaalbaar. Bij het uitblijven van betaling van de factuur zijn – zonder voorafgaande
      ingebrekestelling - verwijlintresten verschuldigd a rato van 10% per jaar. Onder dezelfde voorwaarden is een
      schadevergoeding van 10% verschuldigd op het uitstaande bedrag met een minimum van € 250 en een maximum van € 5.000. De
      advocaat behoudt zich het recht voor de uitvoering van de diensten met een redelijke opzeggingstermijn stop te zetten of op
      te schorten, om reden van niet-betaling van gevraagde voorschotten of van staten van erelonen en kosten na een periode van
      30 dagen of meer of om een andere ernstige reden.
    </p>
    <Styledh4>Administratieve kosten:</Styledh4>
    <p>
      De advocaat rekent een forfaitaire vergoeding van 12% aan op de erelonen, met een minimum van € 75,00 voor gewone algemene
      administratieve kosten eigen aan de normale werking van het kantoor.
    </p>
    <Styledh4>Uitgaven/Kosten:</Styledh4>
    <p>
      Gemaakte uitgaven die verband houden met de individuele behandeling van het dossier (zoals de erelonen van experten en
      buitenlandse advocaten, reis- en cateringkosten, kosten van koerier- en speciale verzendingsdiensten, van opzoekwerk en het
      binden van documenten, videoconferentie-installaties, enz.) worden apart vermeld en gefactureerd aan de kostprijs.
    </p>
    <Styledh4>Indexering</Styledh4>
    <p>De advocaat behoudt zich het recht voor om de ereloontarieven en barema’s jaarlijks te indexeren. </p>
    <Styledh4>BTW:</Styledh4>
    <p>Op al onze uitgaande facturen is 21% BTW verschuldigd.</p>
    <Styledh3>3. Derdenrekeningen</Styledh3>
    <p>
      Gelden die de advocaat namens de cliënt houdt of ontvangt, worden op een speciale door de advocaat in overeenkomst met de
      balievoorschriften gehouden bankrekening, een zogenaamde derdenrekening, geboekt. De cliënt geeft de advocaat de toestemming
      om nog verschuldigde erelonen en uitgaven (cf. supra) af te houden van de namens de cliënt ontvangen bedragen. Deze
      afhouding doet zich slechts uitzonderlijk voor en dient steeds in de gedetailleerde afrekening te worden vermeld.
    </p>
    <Styledh3>4. Aansprakelijkheid</Styledh3>
    <Styledh4>Beperking van de aansprakelijkheid:</Styledh4>
    <p>
      De eventuele contractuele of extra-contractuele aansprakelijkheid van advocaten van REYNAERT ADVOCATEN zelf voor verlies,
      schade, kosten of uitgaven ontstaan in verband met de uitvoering van de diensten zal, met uitzondering van bedrog, in elk
      geval beperkt zijn tot het bedrag van dekking door de beroepsaansprakelijkheidsverzekering van de betrokken
      advocaat-vennoot. Dit geldt onlosmakelijk verbonden aan de individuele aansprakelijkheid van de betrokken en in het dossier
      diensten leverende advocaat, waarbij elk der prestaties leverende deelnemende advocaat in zijn aansprakelijkheid beperkt is
      tot de maximale dekking van zijn deontologische verplichte beroepsaansprakelijkheidsverzekering. Klachten en bemerkingen
      m.b.t. de aan de cliënt overgemaakte provisienota’s en staten van onkosten en ereloon dienen binnen een termijn van 14 dagen
      na verzending van de staat per aangetekende brief te worden overgemaakt aan het kantoor. Indien een provisienota of een
      staat van onkosten en ereloon ondanks verzoek niet wordt voldaan, worden de werkzaamheden opgeschort nadat de cliënt hiervan
      schriftelijk op de hoogte is gesteld. REYNAERT ADVOCATEN of haar medewerkers-advocaten zijn niet aansprakelijk voor schade
      welke ontstaat als gevolg van de opschorting van de werkzaamheden om voormelde reden. Elke provisienota en staat van
      onkosten en ereloon is contant betaaldbaar, bij gebreke waaraan het onbetaald bedrag wordt verhoogd met een intrest van 10 %
      per jaar en een forfaitaire schadeloosstelling van 10% met een minimum van € 250 en een maximum van € 5.000. In geval van
      betwisting m.b.t. de staat van onkosten en ereloon is uitsluitend de reglementering van de Orde van Advocaten en de
      Belgische wetgeving van toepassing en zijn uitsluitend de rechtbanken van het rechtsgebied waar het kantoor van REYNAERT
      ADVOCATEN is gevestigd.
    </p>
    <Styledh4>Geen aansprakelijkheid voor nalatigheden van de cliënt:</Styledh4>
    <p>
      De advocaat zal niet aansprakelijk zijn voor eventuele verliezen, schade, kosten of uitgaven die op enigerlei wijze zouden
      ontstaan uit (bedrieglijke) handelingen of nalatigheden, weglatingen, onjuiste of onvolledige verklaringen of onrechtmatige
      daden in hoofde van de cliënt, diens bestuurders, werknemers, tussenpersonen of onderaannemers. De advocaat behoudt zich het
      recht voor om de eventuele schade op de cliënt te verhalen.
    </p>
    <Styledh4>Geen aansprakelijkheid voor nalatigheden van derden:</Styledh4>
    <p>
      De advocaat is niet aansprakelijk voor handelingen of nalatigheden die plaatsvonden vóór de aanvang van de uitvoering van de
      diensten. Zelfs wanneer de advocaat advies heeft verleend op basis van informatie of raadgevingen die werden overgemaakt
      door buitenlandse of andere advocaten of adviseurs op wie de advocaat een beroep heeft gedaan zonder dat de cliënt de
      advocaat daartoe de opdracht gaf, is elke aansprakelijkheid van de advocaat voor verlies, schade, kosten of uitgaven
      rechtstreeks of onrechtstreeks ontstaan uit een handeling of nalatigheid in hoofde van derde(n), uitgesloten
    </p>
    <Styledh4>Gebruik van documentatie:</Styledh4>
    <p>
      De advocaat aanvaardt geen enkele aansprakelijkheid voor schade ontstaan uit het gebruik van documenten in een andere dan de
      door de advocaat geleverde vorm of in andere situaties dan deze waarvoor de documenten opgesteld werden.
    </p>
    <Styledh4>Overmacht:</Styledh4>
    <p>
      De advocaat aanvaardt geen enkele aansprakelijkheid voor vertraging of het niet uitvoeren van zijn verplichtingen wanneer
      die vertraging of niet-uitvoering het gevolg is van omstandigheden die, redelijkerwijs, buiten de controle van de advocaat
      liggen.
    </p>
    <Styledh3>5. Documentatie</Styledh3>
    <p>
      Vernietiging van documentatie: Het bij het kantoor aanwezige dossier (met uitzondering van de documenten waarvan de
      Opdrachtgever de teruggave vraagt) wordt niet langer dan vijf jaar bewaard, na versturing van de definitieve staat van
      erelonen en kosten.{' '}
    </p>
    <Styledh3>6. Anti-witwas- en anti-terrorisme-financiering</Styledh3>
    <p>
      Wanneer de advocaat de cliënt bijstaat bij het voorbereiden of uitvoeren van verrichtingen die verband houden met de aan- of
      verkoop van onroerend goed of bedrijven, het beheren van diens geld, effecten of andere activa, of transacties of
      handelingen met financiële implicaties van welke aard ook, is hij/zij verplicht de wetgeving en baliereglementering inzake
      antiwitwas- en antiterrorismefinanciering strikt na te leven. Overeenkomstig de terzake geldende regels, is de advocaat
      voornamelijk gehouden tot een identificatie- en waakzaamheidsplicht ten aanzien van zijn cliënt. Deze procedure vergt de
      medewerking van de cliënt en verplicht cliënten die werken onder vennootschapsvorm overeenkomstig de Anti-witwaswet van 18
      september 2017 om hun advocaat mee te delen wie de uiteindelijke begunstigde achter deze vennootschap of opeenvolging van
      vennootschappen is, alsook om hun advocaat in te lichten telkens wanneer deze situatie wijzigt. Indien de cliënt binnen de 2
      weken nadat deze werd opgevraagd weigert de informatie te verstrekken die de advocaat verplicht moet opvragen, heeft de
      advocaat het recht om een einde te stellen aan zijn tussenkomst. Daarnaast verplicht de antiwitwaswetgeving de advocaat in
      bepaalde omstandigheden om mogelijke witwasoperaties en financiering van terrorisme waarin de cliënt zou betrokken zijn, te
      melden aan de Stafhouder van zijn/haar balie. Zie in dat verband : Codex Deontologie voor Advocaten, Orde Vlaamse Balies,
      Afdeling III.1.2. Witwaspreventie :
    </p>
    <Styleda href="https://www.advocaat.be/DipladWebsite/media/DipladMediaLibrary/Documenten/Deontologie/Codex-Deontologie-versie-update-tot-BS-15-01-2019.pdf">
      Codex Deontologie
    </Styleda>
  </React.Fragment>
);

export default GeneralTerms;

const Styledh2 = styled.h2`
  border-left: 3px solid #d26426;
  padding-left: 5px;
  margin-bottom: 20px;
  margin-top: 20px;
`;

const Styledh3 = styled.h3`
  margin-bottom: 5px;
  margin-top: 5px;
`;

const Styledh4 = styled.h4`
  text-decoration: underline;
`;

const Styleda = styled.a`
  color: #d26426;
`;
