import React from 'react';
import styled from 'styled-components';

const AboutTheFirm = () => (
  <React.Fragment>
    <Styledh2>Over het kantoor</Styledh2>
    <p>
      Het advocatenkantoor REYNAERT ADVOCATEN is een onderneming met als KBO nr. 0679.457.482 en met BTW nr. BE 0679.457.482 en
      houdt kantoor te 8670 Koksijde, Zonstraat 21.
    </p>
    <p>De gegevens van het kantoor zijn:</p>
    <ul>
      <li>Naam: Reynaert Advocaten</li>
      <li>Advocaat: Frank Reynaert</li>
      <li>Kantoor: Zonstraat 21, B 8670 Koksijde</li>
      <li>Telefoon: +32 58 59 47 74</li>
      <li>GSM: GSM +32 475 24 78 56</li>
      <li>Email: frank@reynaertadvocaten.be</li>
      <li>Website: www.reynaertadvocaten.be</li>
    </ul>
  </React.Fragment>
);

export default AboutTheFirm;

const Styledh2 = styled.h2`
  border-left: 3px solid #d26426;
  padding-left: 5px;
  margin-bottom: 20px;
  margin-top: 20px;
`;
